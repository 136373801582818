window.$ = require('jquery');
// import 'gdpr-cookie/gdpr-cookie.js';
// import Cookies from 'js-cookie/src/js.cookie.js';

require('./cookies-build');

$(document).ready(function () {
    /**
     * Cookies
     */
    // $(document.body).on('gdpr:accept', function () {
    //     Cookies.set('cookies-accepted', 'true');
    // });
    // if (Cookies.get('cookies-accepted') != 'true') {
    //     $.gdprcookie.init({
    //         title: 'Polityka prywatności i cookies',
    //         subtitle: '',
    //         message:
    //             'Ta strona przetwarza Twoje dane osobowe. Aby dowiedzieć się więcej kliknij w link <a href="https://motomar.com.pl/polityka-prywatnosci" style="text-decoration: underline;">Polityka prywatności</a>',
    //         acceptBtnLabel: 'Zgadzam się',
    //         advancedBtnLabel: 'Zobacz szczegóły',
    //         unadvancedBtnLabel: 'Wstecz',
    //         allowUnadvanced: true,
    //         cookieTypes: [
    //             {
    //                 type: 'Przetwarzanie danych osobowych',
    //                 value: 'privacy',
    //                 checked: true,
    //             },
    //         ],
    //     });
    // }

    /**
     * Homepage big-box fixed
     */
    if ($('.fixed-on-scroll').length > 0) {
        var elementPosition = $('.fixed-on-scroll').offset();
        $(window).scroll(function () {
            if (window.outerWidth > 992) {
                const elementHeight = $('.fixed-on-scroll').outerHeight(true);
                const elementWidth = $('.fixed-on-scroll').outerWidth(true);
                if ($(window).scrollTop() + 73 > elementPosition.top) {
                    $('.fixed-on-scroll')
                        .css('position', 'fixed')
                        .css('top', '0')
                        .css('height', elementHeight)
                        .css('width', elementWidth);
                } else {
                    $('.fixed-on-scroll')
                        .css('position', 'absolute')
                        .css('top', 'auto');
                }
            }
        });
    }

    /**
     * Menu mobile
     */
    $('.menu-button').on('click', function () {
        $('.menu-mobile-overlay').animate({ right: 0 }, 640);
    });

    $('.x').on('click', function () {
        $('.menu-mobile-overlay').animate({ right: '-100%' }, 640);
    });

    /**
     * Square map
     */
    $('.square-map').height($('.square-map').width());

    /**
     * Slide
     */
    $('.slide-button').on('click', function () {
        $(this).siblings('.hide').slideDown(640);
        $(this).hide();
    });

    $('.slide-up-button').on('click', function () {
        $(this)
            .parent('div')
            .parent('div')
            .parent('.hide')
            .slideUp(640, function () {
                $(this).siblings('.slide-button').show();
            });
    });

    /**
     * Tabs
     */
    var i = 0;
    while ($('#btn-tab-' + i).length) {
        let btn = $('#btn-tab-' + i),
            tab = $('#tab-' + i);
        btn.on('click', function (e) {
            e.preventDefault();
            tab.slideToggle(640);
            btn.toggleClass('coiled')
                .toggleClass('active')
                .toggleClass('outline');
        });
        i++;
    }

    /**
     * Smooth scroll
     */
    $('a').on('click', function (event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== '' && $(hash).length) {
            // Prevent default anchor click behavior
            event.preventDefault();
            // Store hash
            var hash = this.hash;
            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            console.log(hash);
            $('html, body').animate(
                {
                    scrollTop: $(hash).offset().top,
                },
                800,
                function () {
                    // Add hash (#) to URL when done scrolling (default click behavior)
                    window.location.hash = hash;
                }
            );
        }
    });
});
